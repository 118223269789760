<template>
  <div class="fundingDetails">
    <!-- 上面提示 -->
    <div class="pageTip">
      <!-- 按照状态判断  进行查看 -->
      <div v-if="dataDetailsObj.financeStatus === '99'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>您的申请单需要完成支付
        </div>
      </div>
      <div v-else-if="dataDetailsObj.financeStatus === '335'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>您有一份待签署的融资协议~
        </div>
      </div>
      <div v-else-if="dataDetailsObj.financeStatus === '336'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>待担保方签署
        </div>
      </div>
      <div
        v-else-if="dataDetailsObj.financeStatus === '339' || dataDetailsObj.financeStatus === '338'"
      >
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>请最晚于{{ dataDetailsObj.repaymentDeadline }}归还借款
        </div>
      </div>
      <div v-else-if="dataDetailsObj.financeStatus === '1'||dataDetailsObj.financeStatus === '334'||dataDetailsObj.financeStatus === '2'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>您的申请正在审核中~
        </div>
      </div>
      <div v-else-if="dataDetailsObj.financeStatus === '337'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>请您耐心等待放款
        </div>
      </div>
      <div v-else-if="dataDetailsObj.financeStatus === '340'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>借款已全部结清，您的授信额度已恢复
        </div>
      </div>
      <div v-else-if="dataDetailsObj.financeStatus === '-1'||dataDetailsObj.financeStatus === '333'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>您的申请未通过。理由是：{{ dataDetailsObj.reviewRemarks||'' }}
        </div>
      </div>
      <div v-else-if="dataDetailsObj.financeStatus === '100'">
        <div class="flex">
          <el-icon :size="18" color="#1363E8">
            <InfoFilled />
          </el-icon>您的申请已取消
        </div>
      </div>
    </div>
    <h3 class="fundingDetailsTitle">
      融资详情
      <el-button v-if="!['-1', '99'].includes(resourceByTaskObj.auditResult)" type="primary" size="small" class="confirm-audit" @click="confirmAudit">
        审核
      </el-button>
    </h3>

    <!-- 融资费用表 -->
    <div class="financialCharges">
      <div class="detailsBox" style="padding: 0">
        <el-row style="margin-top: 0">
          <el-col :span="8">
            <div class="single">
              <span>融资编号：</span>
              <p>{{ dataDetailsObj.financeNo }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="single">
              <span>授信单号：</span>
              <p>{{ dataDetailsObj.creditNo }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="single">
              <span>关联订单号：</span>
              <p>{{ dataDetailsObj.orderNo }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 金额的盒子 -->
      <div class="moneyBox">
        <!-- 左边 -->
        <div class="money">
          <div class="header">
            <img src="@/assets/image/bg1.png" alt="bg">
            <p>
              融资金额：<span>￥{{ dataDetailsObj.applyFinancingAmount || 0 }}</span>
            </p>
          </div>
          <div class="moneyList">
            <p class="list">
              <span class="name">综合借款成本：</span>{{ dataDetailsObj.totalLoanCost||0 }}%/年
            </p>
            <p class="list">
              <span class="name">融资到账金额：</span>{{ dataDetailsObj.actualAmount || 0 }}元
            </p>
            <p
              v-for="(item, index) in dataDetailsObj.financeCostDetailResps"
              :key="index"
              class="list"
            >
              <span class="name">{{ item.costName }}：</span>{{ item.finaningCost||0 }}元
              <!-- <span v-if="item.chargingRule === 1">%</span>
              <span v-else-if="item.chargingRule === 2">%/年</span>
              <span v-else-if="item.chargingRule === 3">元/比</span> -->
            </p>
          </div>
        </div>
        <!-- 右边 -->
        <div class="money">
          <div class="header">
            <img src="@/assets/image/bg2.png" alt="bg">
            <p>
              已还款总额：<span>￥{{ dataDetailsObj.paidAmount || 0 }}</span>
            </p>
          </div>
          <div class="moneyList">
            <p class="list">
              <span class="name">申请时间：</span>{{ dataDetailsObj.createTime }}
            </p>
            <p class="list">
              <span class="name">审核时间：</span>{{ dataDetailsObj.auditTime }}
            </p>
            <p class="list">
              <span class="name">放款时间：</span>{{ dataDetailsObj.loanDate }}
            </p>
            <p class="list">
              <span class="name">还款截止日：</span>{{ dataDetailsObj.repaymentDeadline }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 融资详情 -->
    <div class="detailsBox detailsBox2">
      <h3 class="hubTitle">
        融资详情
      </h3>
      <el-row>
        <el-col :span="8">
          <div class="single">
            <span>融资方：</span>
            <p>{{ dataDetailsObj.buyerCmpName }}</p>
          </div>
          <div class="single">
            <span>贷款周期：</span>
            <p>
              {{ dataDetailsObj.maxLoanCycle }}
              <span v-if="dataDetailsObj.maxLoanCycleUnit === 1">天</span>
              <span v-else-if="dataDetailsObj.maxLoanCycleUnit === 2">月</span>
              <span v-else-if="dataDetailsObj.maxLoanCycleUnit === 3">年</span>
            </p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="single">
            <span>资金方：</span>
            <p>{{ dataDetailsObj.funderName }}</p>
          </div>
          <div class="single">
            <span>收款企业：</span>
            <p>{{ dataDetailsObj.cmpName }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="single">
            <span>担保方：</span>
            <p>{{ dataDetailsObj.guaranteeName }}</p>
          </div>
          <div class="single">
            <span>收款账户：</span>
            <p>
              {{ dataDetailsObj.settleBankId }}--{{
                dataDetailsObj.accountInfo
              }}
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 还款记录 -->
    <div
      v-if="dataDetailsObj.scfRepaymentHistoryResps&&dataDetailsObj.scfRepaymentHistoryResps.length > 0"
      class="tableBox"
    >
      <h3 class="title">
        还款记录
      </h3>
      <div class="table">
        <div class="header">
          <span class="width1">还款时间</span>
          <span class="width2">还款金额</span>
          <span class="width3">还款利息</span>
          <span class="width4">剩余还款金额</span>
          <span class="width5">还款渠道</span>
        </div>
        <div
          v-for="(item, index) in dataDetailsObj.scfRepaymentHistoryResps"
          :key="index"
          class="body"
        >
          <span class="width1">{{ item.repaymentDate }}</span>
          <span class="width2">￥{{ item.repaymentAmount || 0 }}</span>
          <span class="width3">￥{{ item.repaymentInterest || 0 }}</span>
          <span class="width4">￥{{ item.restRepaymentAmount || 0 }}</span>
          <span class="width5 color">{{ item.repaymentChannel }}</span>
        </div>
      </div>
    </div>
    <!-- 相关协议 -->
    <div
      v-if="dataDetailsObj.scfFinanceAgreementResps&&dataDetailsObj.scfFinanceAgreementResps.length > 0"
      class="tableBox"
    >
      <h3 class="title">
        相关协议
      </h3>
      <div
        v-for="(item, index) in dataDetailsObj.scfFinanceAgreementResps"
        :key="index"
        class="documentAgreement"
      >
        <span class="elp-1">{{ item.compactName }}</span>
        <div class="fileBox">
          <img
            v-if="item.type === 'pdf'"
            src="@/assets/image/fileType1.png"
            alt=""
          >
          <img
            v-else-if="item.type === 'doc' || item.type === 'docx'"
            src="@/assets/image/fileType3.png"
            alt=""
          >
          <img
            v-else-if="item.type === 'xlsx' || item.type === 'xls'"
            src="@/assets/image/fileType4.png"
            alt=""
          >
          <img
            v-else-if="item.type === 'zip'"
            src="@/assets/image/fileType2.png"
            alt=""
          >
          <img
            v-else-if="
              item.type === 'png' || item.type === 'jpeg' || item.type === 'jpg'
            "
            :src="$fileUrl + item.compactUrl"
            alt=""
          >
          <span class="name elp-1">{{ item.compactName }}.{{ item.type }}</span>
        </div>
        <div class="operation">
          <el-button
            link
            type="primary"
            size="small"
            @click="downloadListFile(item)"
          >
            下载
          </el-button>
          <el-button
            v-if="
              item.type === 'png' || item.type === 'jpeg' || item.type === 'jpg'|| item.type === 'pdf'
            "
            link
            type="primary"
            size="small"
            @click="checkFile($fileUrl + item.compactUrl)"
          >
            查看
          </el-button>
          <el-button v-else link type="primary" size="small" disabled>
            查看
          </el-button>
        </div>
      </div>
    </div>
    <!-- 审核弹窗 -->
    <Dialog ref="dialog" dialog-width="40%" :form-item="formItem" @getFormData="getFormData" @changeFormSelect="changeFormSelect" />
  </div>
</template>

<script>
import { detailsFinanceManagement } from '@/api/orderFinancing'
import { financeAudit } from '@/api/memberManagement'
import { downloadFile2 } from '@/utils/util'
import { getResourceByTask } from '@/api/memberManagement'
import Dialog from '@/components/Dialog.vue'
export default {
  components: { Dialog },
  data() {
    return {
      dataDetailsObj: {},
      formItem: [
        {
          label: '审核状态',
          type: 'select',
          value: 'approveResult',
          optionLabel: 'VARIABLE_NAME',
          optionId: 'VARIABLE_CODE',
          child: []
        },
        {
          label: '原因',
          type: 'input',
          value: 'approveAdvise',
          rules: 'none'
        }
      ],
      resourceByTaskObj: {
        auditResult: ''
      }
    }
  },
  mounted() {
    detailsFinanceManagement(this.$route.query.id, res => {
      res.data.scfFinanceAgreementResps.forEach(item => {
        if (item.compactUrl) item.type = item.compactUrl.split('.')[1]
      })
      this.dataDetailsObj = { ...res.data }
    })
    this.resourceByTaskObj = this.$store.state.resourceByTaskObj || JSON.parse(sessionStorage.getItem('resourceByTaskObj'))
  },
  methods: {
    downloadListFile(row) {
      downloadFile2(this.$fileUrl + row.compactUrl, row.compactName, row.type)
    },
    // 查看文件
    checkFile(url) {
      window.open(url)
    },
    // 确认审核按钮
    confirmAudit() {
      this.$refs.dialog.editFormVisible = true
      getResourceByTask(this.resourceByTaskObj, res => {
        this.formItem.forEach(item => {
          if (item.value === 'approveResult') {
            item.child = [...res.data]
          }
        })
      })
    },
    // 审核提交
    getFormData(value) {
      const obj = {
        ...value,
        applyCode: this.dataDetailsObj.id,
        activiteId: this.resourceByTaskObj.activiteId,
        cmpState: this.resourceByTaskObj.auditStatus,
        currentNodeId: this.resourceByTaskObj.currentNodeId
      }
      financeAudit(obj, () => {
        this.$refs.dialog.editFormVisible = false
        this.$message.success('成功！')
        this.$router.go(-1)
      })
    },
    changeFormSelect(val) {
      if (val === 'approve_refuse') return delete this.formItem[1].rules
      if (val === 'approve_refuse') return this.formItem[1].rules
      this.$set(this.formItem[1], 'rules', 'none')
    }
  }
}
</script>

<style lang="scss">
.fundingDetails {
  padding-bottom: 48px;
  position: relative;
  .tableBox {
    margin-top: 16px;
    padding: 20px 24px 32px;
    background-color: #fff;
    border-radius: 4px;
    .documentAgreement {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      .operation {
        font-size: 14px;
        color: #1363e8;
      }
      .fileBox {
        display: flex;
        align-items: center;
        .name {
          color: #666;
          font-size: 14px;
          width: 340px;
        }
        img {
          height: 24px;
          width: 24px;
          margin-right: 12px;
        }
      }
      > span {
        color: #666;
        font-size: 16px;
        width: 186px;
      }
    }
    .documentAgreement:last-of-type {
      margin-bottom: 0;
    }
    .table {
      width: 100%;
      font-size: 14px;
      color: #333;
      .body {
        border-bottom: 1px solid #e7e7e7;
        height: 54px;
      }
      .header {
        background-color: rgba(247, 247, 247, 1);
        height: 46px;
      }
      .body,
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      span {
        text-align: center;
      }
      .width1 {
        padding-left: 24px;
        text-align: left;
        width: 15%;
      }
      .width2,
      .width3,
      .width4,
      .width6 {
        width: 23.3%;
      }
      .width5 {
        width: 15%;
        padding-right: 24px;
        text-align: right;
      }
    }
    .title {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 28px;
    }
  }
  .financialCharges {
    background-color: #fff;
    border-radius: 4px;
    padding: 24px 24px 32px;
    .moneyBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .moneyList {
        border: 1px solid #e7e7e7;
        border-top: 0;
        height: 204px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .list {
          padding: 0 24px;
          font-size: 14px;
          color: #333;
          .name {
            margin-top: 16px;
            display: inline-block;
            color: #666;
            width: 200px;
          }
        }
      }
      .money {
        height: 267px;
        width: calc(50% - 8px);
        margin-right: 16px;
        overflow: hidden;
        .header {
          position: relative;
          border-radius: 4px;
          height: 63px;
          display: flex;
          align-items: center;
          p {
            position: absolute;
            top: 50%;
            left: 24px;
            transform: translateY(-50%);
            color: #fff;
            font-size: 16px;
            display: flex;
            align-items: center;
            span {
              font-size: 24px;
            }
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .money:last-of-type {
        margin-right: 0;
      }
    }
  }
  .detailsBox {
    padding: 24px;
    border-radius: 2px;
    .el-row {
      margin-top: 24px;
    }
    .single {
      margin-bottom: 16px;
      font-size: 14px;
      span {
        color: #666;
      }
      p {
        margin-top: 4px;
        color: #333;
      }
      .number {
        color: #1363e8;
      }
      .money {
        color: #e25a4a;
      }
    }
    .hubTitle {
      font-size: 16px;
      color: #000;
      font-weight: 500;
    }
  }
  .detailsBox2 {
    background-color: #fff;
    margin-top: 16px;
    border-radius: 4px;
  }
  .fundingDetailsTitle {
    color: #000;
    font-size: 20px;
    padding: 56px 0 24px;
    .confirm-audit {
      float: right;
      margin-right: 100px;
    }
  }
  .pageTip {
    position: absolute;
    top: 0;
    left: -24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    background-color: rgba(19, 99, 232, 0.08);
    font-size: 14px;
    color: #000;
    width: calc(100% - 24px);
    padding: 0 24px;
    text-indent: 24px;
    .flex {
      display: flex;
      align-items: center;
    }
    .button {
      font-size: 14px;
      color: #1363e8;
      cursor: pointer;
      span:first-of-type {
        color: #666;
        margin-right: 18px;
      }
    }
    .el-icon {
      margin-right: 8px;
    }
  }
}
</style>
